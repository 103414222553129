import React, { useEffect, useRef } from "react";
import { Beat, NoteValue } from "../../types";
import { Button } from "../Button";
import { MAX_BEATS, NOTE_VALUES } from "./constants";

import styles from "./TimingWidget.module.scss";

interface TimingWidgetProps {
  beats: number;
  onBeatsChange: (beats: Beat) => void;
  note: NoteValue;
  onNoteChange: (note: NoteValue) => void;
  onDismiss: () => void;
}

export const TimingWidget = React.forwardRef(function TimingWidget(
  { beats, onBeatsChange, note, onNoteChange, onDismiss }: TimingWidgetProps,
  ref: React.LegacyRef<HTMLDivElement>
) {
  const handleBeatChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => onBeatsChange(parseInt(event.target.value) as Beat);

  const handleNoteChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => onNoteChange(parseFloat(event.target.value) as NoteValue);

  return (
    <div className={styles["full-screen-wrapper"]}>
      <div ref={ref} className={styles.container}>
        <div className={styles.controls}>
          <div className={styles.timing}>
            <select onChange={handleBeatChange} value={beats}>
              {new Array(MAX_BEATS).fill(0).map((_, index) => (
                <option key={index}>{index + 1}</option>
              ))}
            </select>
            <span>/</span>
            <select onChange={handleNoteChange} value={note}>
              {NOTE_VALUES.map((pair, index) => (
                <option key={index} value={pair.value}>
                  {pair.view}
                </option>
              ))}
            </select>
          </div>
          <Button onClick={onDismiss} text="Ok" />
        </div>
      </div>
    </div>
  );
});
