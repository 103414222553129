import React, { useEffect, useState } from "react";
import { NoteSubdivision } from "../../types";
import classes from "./index.module.scss";

interface BeatsViewerProps {
  activeBeat: number;
  beats: number;
  subdivision: NoteSubdivision;
  debugMode?: boolean;
}

export function BeatsViewer({
  activeBeat,
  beats,
  subdivision,
  debugMode,
}: BeatsViewerProps) {
  const beat = Math.floor(activeBeat * subdivision);

  return (
    <div className={classes["beats-viewer"]}>
      {debugMode && (
        <div style={{ fontSize: "2em" }}>
          <code>{beat}</code>
        </div>
      )}
      {new Array(beats).fill(0).map((_, index) => {
        return (
          <span
            key={index}
            className={index === beat ? classes["active"] : ""}
          ></span>
        );
      })}
    </div>
  );
}
