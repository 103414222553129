import React from "react";
import styles from "./index.module.scss";

interface ButtonProps {
  onClick: () => void;
  text: string;
}

export function Button({ onClick, text }: ButtonProps) {
  return (
    <button className={styles.button} onClick={onClick}>
      <span className={styles.inner}>{text}</span>
    </button>
  );
}
