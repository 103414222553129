import { Timer } from "./timer";
import { Beat, NoteResolution, NoteSubdivision, NoteValue } from "../types";
import { useEffect, useRef, useState } from "react";

const NOTE_LENGTH = 0.05;
const SCHEDULE_AHEAD_TIME = 0.1;

/**
 * This metronome is largely based on
 * https://github.com/cwilso/metronome
 */
export class Metronome {
  private beats;
  private noteValue;
  private subdivision;
  private bpm;
  private timer;
  private playing = false;
  private onDraw;
  private drawFunction: () => void;

  private audioCtx: AudioContext;
  private nextNoteTime: number = 0;
  private beatNumber: number = 0;
  private notesInQueue: Array<{ note: number; time: number }> = [];
  private lastNoteDrawn: number = -1;

  constructor(
    beats: Beat,
    noteValue: NoteValue,
    subdivision: NoteSubdivision,
    bpm: number,
    onDraw: (beat: number) => void
  ) {
    this.beats = beats;
    this.noteValue = noteValue;
    this.subdivision = subdivision;
    this.bpm = bpm;
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.timer = new Timer(() => this.schedule());
    this.onDraw = onDraw;
    this.drawFunction = this.draw.bind(this);
    requestAnimationFrame(this.drawFunction);
  }

  public isPlaying() {
    return this.playing;
  }

  public toggle() {
    if (this.playing) {
      this.stop();
    } else {
      this.play();
    }
  }

  public play() {
    this.reset();
    this.audioCtx.resume();
    this.timer.start();
    this.playing = true;
  }

  public stop() {
    this.timer.stop();
    this.playing = false;
    this.reset();
  }

  public setBeats(beats: Beat) {
    this.beats = beats;
  }

  public setSubdivision(subdivision: NoteSubdivision) {
    this.subdivision = subdivision;
  }

  public setNoteValue(note: NoteValue) {
    this.noteValue = note;
  }

  public setBpm(newBpm: number): number {
    if (newBpm < 40) {
      return 40;
    }

    if (newBpm > 400) {
      return 400;
    }

    this.bpm = newBpm;
    return this.bpm;
  }

  private draw() {
    const currentTime = this.audioCtx.currentTime;
    let currentNote = this.lastNoteDrawn;

    while (
      this.notesInQueue.length &&
      this.notesInQueue[0].time + NOTE_LENGTH < currentTime
    ) {
      currentNote = this.notesInQueue[0].note;
      this.notesInQueue.splice(0, 1);
    }

    if (currentNote !== this.lastNoteDrawn) {
      this.onDraw(currentNote);
    }

    requestAnimationFrame(this.drawFunction);
  }

  private reset() {
    this.beatNumber = 0;
    this.nextNoteTime = this.audioCtx.currentTime;
  }

  private schedule() {
    // while there are notes that will need to play before the next interval,
    // schedule them and advance the pointer.
    while (
      this.nextNoteTime <
      this.audioCtx.currentTime + SCHEDULE_AHEAD_TIME
    ) {
      this.scheduleNote(this.beatNumber, this.nextNoteTime);
    }
  }

  private scheduleNote(beatNumber: number, time: number) {
    const totalBeats = this.beats * 4;
    // how many 1/16 notes have to pass before the next sound
    const beatInterval = (this.subdivision / 4) * 16;

    // when the specified interval between 1/16 notes
    // has been reached, make a sound
    if (beatNumber % beatInterval === 0) {
      // create an oscillator
      const osc = this.audioCtx.createOscillator();
      osc.connect(this.audioCtx.destination);
      if (beatNumber % totalBeats === 0) {
        // beat 0 == high pitch
        osc.frequency.value = 880.0;
      } else {
        // other 16th notes = low pitch
        osc.frequency.value = 220.0;
      }

      osc.start(time);
      osc.stop(time + NOTE_LENGTH);

      this.notesInQueue.push({ note: beatNumber / beatInterval, time: time });
    }

    // here we always update the nextNoteTime to the maximum supported resolution
    const secondsPerBeat = 60.0 / this.bpm;
    this.nextNoteTime += 4 * NoteResolution.Sixteenth * secondsPerBeat;

    this.beatNumber++; // Advance the beat number, wrap to zero
    if (this.beatNumber >= totalBeats) {
      this.beatNumber = 0;
    }
  }
}

export function useMetronome(
  beats: Beat,
  noteValue: NoteValue,
  subdivision: NoteSubdivision,
  bpmValue: number,
  onBeatUpdate: (beat: number) => void
) {
  // NOTE: using a ref because I want to make sure the metronome
  // won't be reinstantiated during multiple renderings
  const metronomeRef = useRef<Metronome>();
  const [metronomeBeats, setMetronomeBeats] = useState<Beat>(beats);
  const [metronomeSubdivision, setMetronomeSubdivision] =
    useState<NoteSubdivision>(subdivision);
  const [metronomeNoteValue, setMetronomeNoteValue] =
    useState<NoteValue>(noteValue);
  const [metronomeBpm, setMetronomeBpm] = useState<number>(bpmValue);
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    metronomeRef.current = new Metronome(
      metronomeBeats,
      noteValue,
      subdivision,
      bpmValue,
      onBeatUpdate
    );
  }, []);

  function play() {
    metronomeRef.current?.play();
    setPlaying(true);
  }

  function stop() {
    metronomeRef.current?.stop();
    setPlaying(false);
  }

  function toggle() {
    if (isPlaying) {
      stop();
    } else {
      play();
    }
  }

  function setBeats(beats: Beat) {
    metronomeRef.current?.setBeats(beats);
    setMetronomeBeats(beats);
  }

  function setSubdivision(subdivision: NoteSubdivision) {
    metronomeRef.current?.setSubdivision(subdivision);
    setMetronomeSubdivision(subdivision);
  }

  function setNoteValue(note: NoteValue) {
    metronomeRef.current?.setNoteValue(note);
    setMetronomeNoteValue(note);
  }

  function setBpm(newBpm: number) {
    const actualBpmValue = metronomeRef.current?.setBpm(newBpm);
    setMetronomeBpm(actualBpmValue!);
  }

  return {
    play,
    stop,
    toggle,
    isPlaying,
    setBeats,
    beats: metronomeBeats,
    subdivision: metronomeSubdivision,
    setSubdivision,
    noteValue: metronomeNoteValue,
    setNoteValue,
    bpm: metronomeBpm,
    setBpm,
  };
}
