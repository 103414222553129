import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

export interface SelectOption {
  value: any;
  view: any;
}

interface SelectProps {
  value: any;
  // view: any;
  onChange: (value: any) => void;
  options: SelectOption[];
}

const MAX_WIDTH = 300;
const MAX_HEIGHT = 500;
const MARGIN_X = 16;
const MARGIN_Y = 16;

// fixed width and height for simplicity, this should be calculated
const TYPICAL_WIDTH = 75;
const ROW_HEIGHT = 45;

export function Select({ value, options, onChange }: SelectProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(undefined);
  const [isOpen, setOpen] = useState(false);
  const [collisionOffset, setCollisionOffset] = useState({
    x: 0,
    y: 0,
  });

  const handleOpen = (event: MouseEvent) => {
    if (event.target !== selectRef.current) {
      setOpen(false);
      return;
    }

    const isOpenNow = !isOpen;
    setOpen(isOpenNow);

    if (!isOpenNow) {
      return;
    }

    const newCollisionOffset = { x: 0, y: 0 };
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (containerRect) {
      const maxX = containerRect.x + TYPICAL_WIDTH;
      const maxY = containerRect.y + ROW_HEIGHT * options.length;

      if (maxX > window.innerWidth) {
        console.log("out of screen on x axis");
        newCollisionOffset.x = maxX - window.innerWidth + MARGIN_X;
      }

      if (maxY > window.innerHeight) {
        console.log("out of screen on y axis");
        newCollisionOffset.y = maxY - window.innerHeight + MARGIN_Y;
      }
    }

    setCollisionOffset(newCollisionOffset);
  };

  const handleClickOption = (value: any) => onChange(value);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedOption(selectedOption);
  }, [value, options]);

  useEffect(() => {
    document.addEventListener("click", handleOpen);

    return () => {
      document.removeEventListener("click", handleOpen);
    };
  });

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.selected}>
        <div ref={selectRef} className={styles.inner}>
          {selectedOption?.view}
        </div>
      </div>
      <div
        className={styles["options-container"]}
        style={{
          top: `-${collisionOffset.y}px`,
          left: `-${collisionOffset.x}px`,
          maxHeight: !isOpen ? "0" : `${MAX_HEIGHT}px`,
          maxWidth: !isOpen ? "0" : `${MAX_WIDTH}px`,
        }}
      >
        <ul>
          {options.map((option, index) => (
            <li key={index} onClick={handleClickOption.bind(null, option)}>
              {option.view}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
