import React, { useState } from "react";
import { Knob, KnobData } from "./components/Knob";
import { Beat, NoteSubdivision, NoteValue } from "./types";
import { useMetronome } from "./metronome";
import { BeatsViewer } from "./components/BeatsViewer";
import { ButtonControls } from "./components/ButtonControls";
import styles from "./App.module.scss";

const DEBUG = false;

export default function App() {
  const [activeBeat, setActiveBeat] = useState(0);
  const [knobAmount, setKnobAmount] = useState(0);
  const [isClockwise, setClockwise] = useState<boolean | undefined>(undefined);

  /**
   * A better way to set the activeBeat would be to keep a queue of the "beats"
   * that needs to be visualized inside the metronome itself, then
   * start polling the queue in a requestAnimationFrame loop
   * so that we animate it smoothly
   *
   * reference:
   * https://codepen.io/HunorMarton/pen/EqmyMN?editors=0110
   */

  // 4/4
  const {
    beats,
    setBeats,
    noteValue,
    setNoteValue,
    subdivision,
    setSubdivision,
    toggle,
    isPlaying,
    bpm,
    setBpm,
  } = useMetronome(
    4,
    NoteValue.Quarter,
    NoteSubdivision.Quarter,
    130,
    (beatNumber) => {
      setActiveBeat(beatNumber);
    }
  );

  function handleClick() {
    toggle();
  }

  function onKnobMove(data: KnobData) {
    const newBpmValue = bpm + data.amount;
    setBpm(newBpmValue);
    setKnobAmount(data.amount);
    setClockwise(data.isClockwise);
  }

  // function handleBpmButtonClick(num: number) {
  //   const newBpmValue = bpm + num;
  //   setBpm(newBpmValue);
  // }

  function onKnobFinish() {
    setKnobAmount(0);
    setClockwise(undefined);
  }

  function handleBeatsChange(newBeatsSelected: number) {
    setBeats(newBeatsSelected as Beat);
  }

  function handleSubdivisionChange(subdivision: NoteSubdivision) {
    setSubdivision(subdivision);
  }

  function handleNoteChange(note: NoteValue) {
    setNoteValue(note);
  }

  return (
    <>
      <h1 className={styles.title}>
        SUPER<span>METRONOME</span>
      </h1>
      <div style={{ marginTop: "32px" }}>
        <BeatsViewer
          activeBeat={activeBeat}
          beats={beats}
          subdivision={subdivision}
        />
      </div>
      <div className={styles["bpm-controls"]}>
        <div className={styles.container}>
          {/* <button onClick={handleBpmButtonClick.bind(null, -1)}>-</button> */}
          <div className={styles.bpm}>
            <h2>{Math.round(bpm)}</h2>
            <small>BPM</small>
          </div>
          {/* <button onClick={handleBpmButtonClick.bind(null, 1)}>+</button> */}
        </div>

        <Knob onMove={onKnobMove} onFinish={onKnobFinish} />
      </div>
      {DEBUG && (
        <ul>
          <li>
            <code>amount = {knobAmount}</code>
          </li>
          <li>
            <code>
              clockwise ={" "}
              {isClockwise !== undefined
                ? isClockwise
                  ? "clockwise"
                  : "counterclockwise"
                : ""}
            </code>
          </li>
        </ul>
      )}
      <ButtonControls
        isPlaying={isPlaying}
        beats={beats}
        onBeatsChange={handleBeatsChange}
        note={noteValue}
        onNoteChange={handleNoteChange}
        subdivision={subdivision}
        onSubdivisionChange={handleSubdivisionChange}
        onPlay={handleClick}
      />
    </>
  );
}
