export class Timer {
  private started: boolean = false;
  private worker: Worker;
  private onTick: () => void;

  constructor(onTick: () => void) {
    this.worker = new Worker(fn2workerURL(workerFunction));
    this.onTick = onTick;
    this.worker.onmessage = (e) => {
      if (e.data === "tick") {
        this.onTick();
      }
    };
  }

  public stop() {
    this.started = false;
    this.worker.postMessage("stop");
  }

  public start() {
    if (this.started) {
      return;
    }

    this.started = true;
    this.worker.postMessage("start");
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers
function fn2workerURL(fn: () => any) {
  var blob = new Blob(["(" + fn.toString() + ")()"], {
    type: "text/javascript",
  });
  return URL.createObjectURL(blob);
}

function workerFunction() {
  let timerID: any = 0;
  let interval = 100;

  onmessage = (e: any) => {
    if (e.data === "start") {
      timerID = setInterval(() => {
        // @ts-ignore
        postMessage("tick");
      }, interval);
    } else if (e.data.interval) {
      interval = e.data.interval;
      if (timerID) {
        clearInterval(timerID);
        timerID = setInterval(() => {
          // @ts-ignore
          postMessage("tick");
        }, interval);
      }
    } else if (e.data === "stop") {
      clearInterval(timerID);
      timerID = undefined;
    }
  };
}
