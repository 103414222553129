import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Beat, NoteSubdivision, NoteValue } from "../../types";
import { Select, SelectOption } from "../Select";
import { TimingWidget } from "./TimingWidget";

import styles from "./index.module.scss";

const NOTES_BY_VALUES = {
  [NoteValue.One]: 1,
  [NoteValue.Half]: 2,
  [NoteValue.Quarter]: 4,
  [NoteValue.Eighth]: 8,
};
export const NOTE_VALUES = [
  { value: NoteValue.One, view: 1 },
  { value: NoteValue.Half, view: 2 },
  { value: NoteValue.Quarter, view: 4 },
  { value: NoteValue.Eighth, view: 8 },
];

const NOTE = {
  [NoteValue.One]: "𝅗",
  [NoteValue.Half]: "𝅗𝅥",
  [NoteValue.Quarter]: "𝅘𝅥",
  [NoteValue.Eighth]: "𝅘𝅥𝅮",
  [NoteValue.Sixteenth]: "𝅘𝅥𝅯",
  [NoteValue.ThirtySecond]: "𝅘𝅥𝅰",
};

/**
 * Given a note value, this function will return an array of pairs in which each couple represents the NoteValue of a symbol
 * and how many of those symbols are needed to make the given note.
 * @param note The note you want to start from to get the variations
 */
function getNoteMultiples(note: NoteValue, count: number) {
  const noteValues = [
    NoteValue.One,
    NoteValue.Half,
    NoteValue.Quarter,
    NoteValue.Eighth,
    NoteValue.Sixteenth,
    NoteValue.ThirtySecond,
  ];
  const countByValue = noteValues.reduce((acc, value) => {
    acc[parseFloat(value.toString())] = note / (value as number);
    return acc;
  }, {} as { [key: number]: number });

  return Object.entries(countByValue)
    .filter((pair) => pair[1] >= 1)
    .slice(0, count);
}

interface ButtonControlsProps {
  onPlay: () => void;
  onBeatsChange: (beats: Beat) => void;
  onSubdivisionChange: (subdivision: NoteSubdivision) => void;
  onNoteChange: (note: NoteValue) => void;
  isPlaying: boolean;
  beats: Beat;
  note: NoteValue;
  subdivision: NoteSubdivision;
}

export function ButtonControls({
  onPlay,
  onBeatsChange,
  onSubdivisionChange,
  onNoteChange,
  subdivision,
  isPlaying,
  beats,
  note,
}: ButtonControlsProps) {
  const timingButtonRef = useRef<HTMLButtonElement>(null);
  const timingWidgetContainerRef = useRef<HTMLDivElement>(null);
  const [timingWidgetVisible, setTimingWidgetVisible] = useState(false);
  const noteMultiples = getNoteMultiples(note, 3);

  const handleTimingButtonClick = () => {
    setTimingWidgetVisible(true);
  };

  const handleSubdivisionChange = (data: SelectOption) => {
    onSubdivisionChange(parseFloat(data.value));
  };

  const handleTimingWidgetDismiss = () => setTimingWidgetVisible(false);

  const handleTimingWidgetContainerClick = (event: MouseEvent) => {
    const { target } = event;

    if (target === timingButtonRef.current) {
      return;
    }

    if (!timingWidgetContainerRef.current?.contains(target as Node)) {
      handleTimingWidgetDismiss();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleTimingWidgetContainerClick);

    return () => {
      document.removeEventListener("click", handleTimingWidgetContainerClick);
    };
  });

  return (
    <>
      <div className={styles.controls}>
        <button className={styles.button} onClick={onPlay}>
          <span className={styles.inner}>
            {isPlaying ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </span>
        </button>

        <button className={styles.button} onClick={handleTimingButtonClick}>
          <span ref={timingButtonRef} className={styles.inner}>
            {beats}/{NOTES_BY_VALUES[note]}
          </span>
        </button>

        <div
          className={classnames(styles.button, styles.noto)}
          style={{ fontSize: "1em" }}
        >
          <Select
            onChange={(data) => {
              handleSubdivisionChange(data);
            }}
            value={subdivision}
            options={[
              NoteSubdivision.One,
              NoteSubdivision.Half,
              NoteSubdivision.Quarter,
            ]
              .map((subdivision, index) => ({
                subdivision,
                noteMultiple: noteMultiples[index],
              }))
              .map((obj) => {
                const { subdivision, noteMultiple } = obj;
                return {
                  value: subdivision,
                  view: new Array(noteMultiple[1])
                    .fill(NOTE[noteMultiple[0] as any])
                    .join(""),
                };
              })}
          />
        </div>

        {timingWidgetVisible && (
          <TimingWidget
            ref={timingWidgetContainerRef}
            beats={beats}
            onBeatsChange={onBeatsChange}
            note={note}
            onNoteChange={onNoteChange}
            onDismiss={handleTimingWidgetDismiss}
          />
        )}
      </div>
    </>
  );
}
