import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

interface Point {
  x: number;
  y: number;
}

function isMouseEvent(event: MouseEvent | TouchEvent): event is MouseEvent {
  return (event as MouseEvent).clientX !== undefined;
}

function mouseToCircle(event: MouseEvent | TouchEvent, rect: DOMRect) {
  const clientX = isMouseEvent(event)
    ? event.clientX
    : event.touches[0].clientX;
  const clientY = isMouseEvent(event)
    ? event.clientY
    : event.touches[0].clientY;

  const x = clientX - (rect.x + rect.width / 2);
  const y = clientY - (rect.y + rect.height / 2);
  return {
    x: (x / rect.width) * 2,
    y: (y / rect.height) * 2,
  };
}

export interface KnobData {
  isClockwise?: boolean;
  amount: number;
}

interface KnobProps {
  onMove: (data: KnobData) => void;
  onFinish?: () => void;
}

export function Knob({ onMove, onFinish }: KnobProps) {
  const [startingPoint, setStartingPoint] = useState<Point | undefined>(
    undefined
  );
  const [angle, setAngle] = useState<number>(0);
  const [startingAngle, setStartingAngle] = useState<number>(angle);
  const [amount, setAmount] = useState<number>(0);
  const [isClockwise, setClockwise] = useState<boolean | undefined>(undefined);
  const controlRef = useRef<HTMLDivElement>(null);

  function handleDragStart(event: MouseEvent | TouchEvent) {
    const control = event.target as HTMLElement;
    const point = mouseToCircle(event, control.getBoundingClientRect());
    setStartingPoint(point);
  }

  function handleMouseMove(event: MouseEvent | TouchEvent) {
    const control = controlRef.current;
    if (startingPoint && control) {
      const currentPoint = mouseToCircle(
        event,
        control.getBoundingClientRect()
      );

      const dotProduct =
        startingPoint.x * currentPoint.x + startingPoint.y * currentPoint.y;
      const determinant =
        startingPoint.x * currentPoint.y - startingPoint.y * currentPoint.x;
      const angleRadians = Math.atan2(determinant, dotProduct);

      const newAngle = startingAngle + angleRadians;

      // angle used in rendering
      setAngle(newAngle);

      // this will calculate if the movement is clockwise or not
      const difference = newAngle - angle;
      const wrapping = Math.abs(difference) >= Math.PI;
      if (!wrapping) {
        let changedDirection = false;
        const epsilon = Math.PI / 1000;
        if (difference > epsilon) {
          if (!isClockwise) {
            changedDirection = true;
          }

          setClockwise(true);
        } else if (difference < -epsilon) {
          if (isClockwise) {
            changedDirection = true;
          }

          setClockwise(false);
        }

        let newAmount = 0;
        if (isClockwise) {
          newAmount = amount + Math.abs(difference);
        } else {
          newAmount = amount - Math.abs(difference);
        }

        // when changing direction, start again from 0
        // so that we take into account just the offset
        if (changedDirection) {
          newAmount -= amount;
        }

        // consider just 80% of movement, so that it's smoother
        newAmount *= 0.8;

        setAmount(newAmount);
        onMove({
          amount,
          isClockwise,
        });
      }
    }
  }

  function handleDragEnd() {
    onFinish?.();
    setStartingPoint(undefined);
    setStartingAngle(angle);
    setAmount(0);
    setClockwise(undefined);
  }

  useEffect(() => {
    const control = controlRef.current;
    control?.addEventListener("mousedown", handleDragStart);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleDragEnd);

    control?.addEventListener("touchstart", handleDragStart);
    window.addEventListener("touchmove", handleMouseMove);
    window.addEventListener("touchend", handleDragEnd);

    return () => {
      control?.removeEventListener("mousedown", handleDragStart);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleDragEnd);

      control?.removeEventListener("touchstart", handleDragStart);
      window.removeEventListener("touchmove", handleMouseMove);
      window.removeEventListener("touchend", handleDragEnd);
    };
  });

  return (
    <>
      <div className={styles.knob}>
        <div
          ref={controlRef}
          className={styles.control}
          style={{ transform: `rotateZ(${angle}rad)` }}
        >
          <div className={styles.tick}></div>
        </div>
      </div>
    </>
  );
}
