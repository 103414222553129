export enum NoteResolution {
  Sixteenth = 1 / 16,
  Eighteenth = 1 / 8,
  Quarter = 1 / 4,
}

export type Beat =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16;

export enum NoteValue {
  One = 1,
  Half = 1 / 2,
  Quarter = 1 / 4,
  Eighth = 1 / 8,
  Sixteenth = 1 / 16,
  ThirtySecond = 1 / 32,
}

export enum NoteSubdivision {
  One = 1,
  Half = 1 / 2,
  Quarter = 1 / 4,
}

export interface Note {
  note?: string;
}
